import React, { Component } from "react";
import { Card, Button } from "react-bootstrap";
import classnames from "classnames";

//import lib
import { toastAlert } from "../../lib/toastAlert";

//import config

// import action
import { updateBankDetails } from "../../actions/adminBankAccount";

const initialFormValue = {
  accountName: '',
  currency: '',
  bic: '',
  accountNumber: '',
  routingNumber: '',
  bankName: '',
  bankAddress: '',
  holderName: '',
  holderCountry: '',
  holderState: '',
  holderCity: '',
  holderZipcode: '',
};

class BankDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formValue: initialFormValue,
      errors: {},
      loader: false,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    const { records } = nextProps;
    if (records) {
      this.setState({
        formValue: {
          accountId: records._id,
          accountName: records.account && records.account.name,
          currency: records.account && records.account.currency,
          bic: records.account && records.account.bic,
          accountNumber: records.account && records.account.number,
          routingNumber: records.account && records.account.routingNumber,
          bankName: records.bank && records.bank.name,
          bankAddress: records.bank && records.bank.address,
          holderName: records.holder && records.holder.name,
          holderCountry: records.holder && records.holder.country,
          holderState: records.holder && records.holder.state,
          holderCity: records.holder && records.holder.city,
          holderZipcode: records.holder && records.holder.zipcode,
        },
      });
    }
  }

  handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    let formData = { ...this.state.formValue, ...{ [name]: value } };
    this.setState({ formValue: formData });
  };

  async handleSubmit(e) {
    const { fetchBankSetting } = this.props;

    const {
      accountId,
      accountName,
      currency,
      bic,
      accountNumber,
      routingNumber,
      bankName,
      bankAddress,
      holderName,
      holderCountry,
      holderState,
      holderCity,
      holderZipcode,
    } = this.state.formValue;

    this.setState({ loader: true });
    try {
      let reqData = {
        accountId,
        accountName,
        currency,
        bic,
        accountNumber,
        routingNumber,
        bankName,
        bankAddress,
        holderName,
        holderCountry,
        holderState,
        holderCity,
        holderZipcode,
      };
      const { status, loading, message, error } = await updateBankDetails(reqData);
      this.setState({ loader: loading });
      if (status == "success") {
        console.log(status, message, 'status message')
        toastAlert("success", message, "bankDetails");
        this.setState({ errors: {} });
        fetchBankSetting();
      } else if (status == "failed") {
        if (error) {
          this.setState({ errors: error });
        } else {
          toastAlert("error", message, "bankDetails");
        }
      }
    } catch (err) { }
  }
  render() {
    const { errors } = this.state;

    const {
      accountName,
      currency,
      bic,
      accountNumber,
      routingNumber,
      bankName,
      bankAddress,
      holderName,
      holderCountry,
      holderState,
      holderCity,
      holderZipcode,
    } = this.state.formValue;

    return (
      <Card className="mb-3">
        <Card.Header>Bank Details</Card.Header>
        <Card.Body>
          {this.props.loader == true ? (
            <p>Loading ...</p>
          ) : (
            <>
              <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="accountName">Account Name</label>
                </div>
                <div className="col-md-9">
                  <input
                    value={accountName}
                    onChange={this.handleChange}
                    name="accountName"
                    error={errors.accountName}
                    id="accountName"
                    type="text"
                    className={classnames("form-control", {
                      invalid: errors.accountName,
                    })}
                  />
                  <span className="text-danger">{errors.accountName}</span>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="currencyName">Currency</label>
                </div>
                <div className="col-md-9">
                  <input
                    value={currency}
                    onChange={this.handleChange}
                    name="currency"
                    error={errors.currency}
                    id="currency"
                    type="text"
                    className={classnames("form-control", {
                      invalid: errors.currency,
                    })}
                  />
                  <span className="text-danger">{errors.currency}</span>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="currencyName">BIC</label>
                </div>
                <div className="col-md-9">
                  <input
                    value={bic}
                    name="bic"
                    onChange={this.handleChange}
                    error={errors.bic}
                    id="bic"
                    type="text"
                    className={classnames("form-control", {
                      invalid: errors.bic,
                    })}
                  />
                  <span className="text-danger">{errors.bic}</span>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="currencyName">Account Number</label>
                </div>
                <div className="col-md-9">
                  <input
                    value={accountNumber}
                    onChange={this.handleChange}
                    name="accountNumber"
                    error={errors.accountNumber}
                    id="accountNumber"
                    type="text"
                    className={classnames("form-control", {
                      invalid: errors.accountNumber,
                    })}
                  />
                  <span className="text-danger">{errors.accountNumber}</span>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="currencyName">Routing Number</label>
                </div>
                <div className="col-md-9">
                  <input
                    value={routingNumber}
                    onChange={this.handleChange}
                    name="routingNumber"
                    error={errors.routingNumber}
                    id="routingNumber"
                    type="text"
                    className={classnames("form-control", {
                      invalid: errors.routingNumber,
                    })}
                  />
                  <span className="text-danger">{errors.routingNumber}</span>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="currencyName">Bank Name</label>
                </div>
                <div className="col-md-9">
                  <input
                    value={bankName}
                    onChange={this.handleChange}
                    name="bankName"
                    error={errors.bankName}
                    id="bankName"
                    type="text"
                    className={classnames("form-control", {
                      invalid: errors.bankName,
                    })}
                  />
                  <span className="text-danger">{errors.bankName}</span>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="currencyName">Bank Address</label>
                </div>
                <div className="col-md-9">
                  <input
                    value={bankAddress}
                    onChange={this.handleChange}
                    name="bankAddress"
                    error={errors.bankAddress}
                    id="bankAddress"
                    type="text"
                    className={classnames("form-control", {
                      invalid: errors.bankAddress,
                    })}
                  />
                  <span className="text-danger">{errors.bankAddress}</span>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="currencyName">Holder Name</label>
                </div>
                <div className="col-md-9">
                  <input
                    value={holderName}
                    onChange={this.handleChange}
                    name="holderName"
                    error={errors.holderName}
                    id="holderName"
                    type="text"
                    className={classnames("form-control", {
                      invalid: errors.holderName,
                    })}
                  />
                  <span className="text-danger">{errors.holderName}</span>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="currencyName">Holder Country</label>
                </div>
                <div className="col-md-9">
                  <input
                    value={holderCountry}
                    onChange={this.handleChange}
                    name="holderCountry"
                    error={errors.holderCountry}
                    id="holderCountry"
                    type="text"
                    className={classnames("form-control", {
                      invalid: errors.holderCountry,
                    })}
                  />
                  <span className="text-danger">{errors.holderCountry}</span>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="currencyName">Holder State</label>
                </div>
                <div className="col-md-9">
                  <input
                    value={holderState}
                    onChange={this.handleChange}
                    name="holderState"
                    error={errors.holderState}
                    id="holderState"
                    type="text"
                    className={classnames("form-control", {
                      invalid: errors.holderState,
                    })}
                  />
                  <span className="text-danger">{errors.holderState}</span>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="currencyName">Holder City</label>
                </div>
                <div className="col-md-9">
                  <input
                    value={holderCity}
                    onChange={this.handleChange}
                    name="holderCity"
                    error={errors.holderCity}
                    id="holderCity"
                    type="text"
                    className={classnames("form-control", {
                      invalid: errors.holderCity,
                    })}
                  />
                  <span className="text-danger">{errors.holderCity}</span>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="currencyName">Holder Zipcode</label>
                </div>
                <div className="col-md-9">
                  <input
                    value={holderZipcode}
                    onChange={this.handleChange}
                    name="holderZipcode"
                    error={errors.holderZipcode}
                    id="holderZipcode"
                    type="text"
                    className={classnames("form-control", {
                      invalid: errors.holderZipcode,
                    })}
                  />
                  <span className="text-danger">{errors.holderZipcode}</span>
                </div>
              </div>
            </>
          )}
        </Card.Body>
        <Card.Footer>
          {this.props.loader === true ? null : (
            <Button onClick={this.handleSubmit}>Submit</Button>
          )}
        </Card.Footer>
      </Card>
    );
  }
}

export default BankDetails;
