import React, { Component, Fragment } from "react";
import ReactDatatable from "@ashvin27/react-datatable";
import { withRouter } from "react-router-dom";

//import component
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";
import ViewTradeModal from "../partials/ViewTradeModal"

//impport action
import { getTrade } from "../../actions/tradeAction";

// import lib
import { momentFormat } from "../../lib/dateTimeHelper";

class Trades extends Component {
  constructor(props) {
    super(props);
    this.columns = [
      {
        key: "createdAt",
        text: "Created date",
        className: "date",
        align: "left",
        sortable: false,
        width: 120,
        cell: (record) => {
          return (
            <Fragment>
              {<p>{momentFormat(record.createdAt, "YYYY-MM-DD HH:mm")}</p>}
            </Fragment>
          );
        },
      },
      {
        key: "accountCode",
        text: "Account code",
        className: "accountCode",
        align: "left",
        sortable: false,
      },
      {
        key: "partnerCode",
        text: "Partner code",
        className: "partnerCode",
        align: "left",
        sortable: false,
      },
      {
        key: "priceQuoteId",
        text: "Price Quote Id",
        className: "priceQuoteId",
        align: "left",
        sortable: false,
      },
      {
        key: "inAmount",
        text: "In Amount",
        className: "inAmount",
        align: "left",
        sortable: false
      },
      {
        key: "outAmountExpected",
        text: "Out Amount Expected",
        className: "outAmountExpected",
        align: "left",
        sortable: false
      },
      {
        key: "inCurrency",
        text: "In Currency",
        className: "transferOut",
        align: "left",
        sortable: false
      },
      {
        key: "outCurrency",
        text: "Out Currency",
        className: "transferOut",
        align: "left",
        sortable: false
      },
      {
        key: "inMedium",
        text: "In Medium",
        className: "transferOut",
        align: "left",
        sortable: false
      },
      {
        key: "outMedium",
        text: "Out Medium",
        className: "transferOut",
        align: "left",
        sortable: false
      },
      {
        key: "state",
        text: "State",
        className: "transferOut",
        align: "left",
        sortable: false
      },
      {
        key: "action",
        text: "Action",
        className: "action",
        width: 100,
        align: "left",
        sortable: false,
        cell: (record) => {
          if (record.state == 'processing' || record.state == 'awaiting_transfer_in') {
            return (
              <button
                className="btn btn-primary btn-sm"
                onClick={() => this.viewRecord(record)}
                style={{ marginRight: "5px" }}
              >
                <i className="fa fa-edit"></i>
              </button>
            );
          }
          return '-'
        },
      },
    ];

    this.config = {
      page_size: 10,
      length_menu: [10, 20, 50],
      filename: "Perpetual",
      no_data_text: "No trade found!",
      language: {
        length_menu: "Show _MENU_ result per page",
        filter: "Filter in records...",
        info: "Showing _START_ to _END_ of _TOTAL_ records",
        pagination: {
          first: "First",
          previous: "Previous",
          next: "Next",
          last: "Last",
        },
      },
      show_length_menu: true,
      show_filter: true,
      show_pagination: true,
      show_info: true,
    };

    this.state = {
      records: [],
      loader: false,
      page: 1,
      limit: 10,
      count: 0,
      viewFormModal: false,
      viewRecord: {},
    };

    this.getTradeList = this.getTradeList.bind(this);
    this.handlePagination = this.handlePagination.bind(this);
    this.handleCloseViewForm = this.handleCloseViewForm.bind(this);
    this.refetch = this.refetch.bind(this);
  }

  componentDidMount() {
    const { page, limit } = this.state;
    let reqData = {
      page,
      limit,
    };
    this.getTradeList(reqData);
  }

  async getTradeList(reqData) {
    try {
      this.setState({ loader: true });
      const { status, loading, result } = await getTrade(reqData);
      this.setState({ loader: loading });
      if (status == "success") {
        this.setState({
          count: result.count,
          records: result.data,
          loader: false,
        });
      }
    } catch (err) { }
  }

  viewRecord(record) {
    this.setState({
      viewFormModal: true,
      viewRecord: record,
    });
  }

  handleCloseViewForm() {
    this.setState({ viewFormModal: false, viewRecord: {} });
  }

  handlePagination(index) {
    const { page, limit } = this.state;

    let reqData = {
      page: index.page_number,
      limit: index.page_size,
      search: index.filter_value,
    };
    this.getTradeList(reqData);

    this.setState({
      page: index.page_number,
      limit: index.page_size,
      search: index.filter_value,
    });
  }


  refetch() {
    const { page, limit } = this.state;
    let reqData = {
      page,
      limit,
    };
    this.getTradeList(reqData);
  }

  render() {
    const { count, loader, records, viewFormModal, viewRecord } = this.state;

    return (
      <div>
        <Navbar />
        <ViewTradeModal
          isShow={viewFormModal}
          onHide={this.handleCloseAddForm}
          fetchData={this.refetch}
          record={viewRecord}
          onHide={this.handleCloseViewForm}
        />
        <div className="d-flex" id="wrapper">
          <Sidebar />
          <div id="page-content-wrapper">
            <div className="container-fluid">
              <h3 className="mt-2 text-secondary">Trade List</h3>

              <div style={{ overflow: "scroll" }}>
                <ReactDatatable
                  responsive={true}
                  config={this.config}
                  loading={loader}
                  records={records}
                  total_record={count}
                  dynamic={true}
                  columns={this.columns}
                  onChange={this.handlePagination}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Trades);
