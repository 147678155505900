// import config
import axios from "../config/axios";

export const getAccount = async (reqData) => {
  try {
    let respData = await axios({
      method: "get",
      url: `/api/account/all`,
      params: reqData,
    });

    return {
      status: "success",
      loading: false,
      result: respData.data.result,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
    };
  }
};

export const sentKycMail = async (reqData) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/api/account/sentKycMail`,
      data: reqData,
    });

    return {
      status: "success",
      loading: false,
      message: respData.data.message,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
    };
  }
};