import React, { Component, Fragment } from "react";
import ReactDatatable from "@ashvin27/react-datatable";
import { withRouter } from 'react-router-dom';
import { Button } from "bootstrap";

//import component
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";

//impport action
import {
  getAccount,
  sentKycMail
} from "../../actions/accountAction";

// import lib
import { momentFormat } from "../../lib/dateTimeHelper";
import { capitalize } from '../../lib/capitalize'
import { toastAlert } from '../../lib/toastAlert'

class Users extends Component {
  constructor(props) {
    super(props);
    this.columns = [
      {
        key: "createdAt",
        text: "Created date",
        className: "date",
        align: "left",
        sortable: false,
        width: 120,
        cell: (record) => {
          return (
            <Fragment>
              {<p>{momentFormat(record.createdAt, "YYYY-MM-DD HH:mm")}</p>}
            </Fragment>
          );
        },
      },
      {
        key: "email",
        text: "Email",
        className: "email",
        align: "left",
        sortable: false,
      },
      {
        key: "accountCode",
        text: "Account Code",
        className: "accountCode",
        align: "left",
        sortable: false,
      },
      {
        key: "accountType",
        text: "Account Type",
        className: "accountType",
        align: "left",
        sortable: false,
      },
      {
        key: "partnerCode",
        text: "Partner Code",
        className: "partnerCode",
        align: "left",
        sortable: false,
      },
      {
        key: "country",
        text: "Country",
        className: "country",
        align: "left",
        sortable: false
      },
      {
        key: "city",
        text: "City",
        className: "city",
        align: "left",
        sortable: false,
      },
      {
        key: "postalCode",
        text: "Postal Code",
        className: "postalCode",
        align: "left",
        sortable: false,
      },
      {
        key: "identificationState",
        text: "KYC State",
        className: "identificationState",
        align: "left",
        sortable: false,
      },
      {
        key: "state",
        text: "Status",
        className: "state",
        align: "left",
        sortable: false,
        cell: (record) => {
          const bg = record.state !== 'active' ? 'badge badge-danger' : 'badge badge-success';
          return <span className={bg}>{capitalize(record.state)}</span>
        },
      },
      {
        key: "state",
        text: "KYC Mail",
        className: "state",
        align: "left",
        sortable: false,
        cell: (record) => {
          if (record.identificationState == 'pending') {
            return (
              <button onClick={() => this.sentMail(record)} >
                Sent Mail
              </button>
            )
          }
          return '-'
        },
      },
      // {
      //   key: "state",
      //   text: "Action",
      //   className: "state",
      //   align: "left",
      //   sortable: true,
      //   cell: (record) => {
      //     return (
      //       <>
      //         <button
      //           className={
      //             record.state === "active"
      //               ? "btn btn-success btn-sm"
      //               : "btn btn-danger btn-sm"
      //           }
      //           onClick={() => this.LockedUser(record._id)}
      //         >
      //           {" "}
      //           {record.state === "verified" ? (
      //             <i class="fa fa-unlock " aria-hidden="true"></i>
      //           ) : (
      //             <i class="fa fa-lock" aria-hidden="true"></i>
      //           )}
      //         </button>
      //       </>
      //     );
      //   },
      // },
    ];

    this.config = {
      page_size: 10,
      length_menu: [10, 20, 50],
      filename: "Perpetual",
      no_data_text: "No Contracts found!",
      language: {
        length_menu: "Show _MENU_ result per page",
        filter: "Filter in records...",
        info: "Showing _START_ to _END_ of _TOTAL_ records",
        pagination: {
          first: "First",
          previous: "Previous",
          next: "Next",
          last: "Last",
        },
      },
      show_length_menu: true,
      show_filter: true,
      show_pagination: true,
      show_info: true,
    };

    this.state = {
      records: [],
      loader: false,
      page: 1,
      limit: 10,
      count: 0,
      userId: "",
    };

    this.getAccountList = this.getAccountList.bind(this);
    this.handlePagination = this.handlePagination.bind(this);
  }

  componentDidMount() {
    const { page, limit } = this.state;
    let reqData = {
      page,
      limit,
    };
    this.getAccountList(reqData);
  }
  async getAccountList(reqData) {
    try {
      this.setState({ loader: true });
      const { status, loading, result } = await getAccount(reqData);
      this.setState({ loader: loading });
      if (status == "success") {
        this.setState({
          count: result.count,
          records: result.data,
          loader: false,
        });
      }
    } catch (err) { }
  }

  async sentMail(record) {
    try {
      let reqData = {
        'accountId': record._id
      }
      const { status, message } = await sentKycMail(reqData)
      if (status == 'success') {
        toastAlert('success', message, 'account')
      }
    } catch (err) {
    }
  }

  handlePagination(index) {
    const { page, limit } = this.state;

    let reqData = {
      page: index.page_number,
      limit: index.page_size,
      search: index.filter_value,
    };
    this.getAccountList(reqData);

    this.setState({
      page: index.page_number,
      limit: index.page_size,
      search: index.filter_value,
    });
  }

  render() {
    const {
      count,
      loader,
      records,
    } = this.state;

    return (
      <div>
        <Navbar />
        <div className="d-flex" id="wrapper">
          <Sidebar />
          <div id="page-content-wrapper">
            <div className="container-fluid">
              <h3 className="mt-2 text-secondary">Account List</h3>

              <div style={{ overflow: "scroll" }}>
                <ReactDatatable
                  responsive={true}
                  config={this.config}
                  loading={loader}
                  records={records}
                  total_record={count}
                  dynamic={true}
                  columns={this.columns}
                  onChange={this.handlePagination}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Users);
