import React from 'react'

class AccountDetails extends React.Component {
    constructor(props) {
        super(props)
    }

    render() {
        const { record } = this.props;
        return (
            <div class="row">
                <div class="col-sm-6">
                    <div class="card">
                        {
                            record.inMedium != 'bank' ?
                                <div class="card-body">
                                    <h5 class="card-title">Transfer In</h5>
                                    <div className='trade-details'>
                                        <div className='d-flex mb-1'>
                                            <span className='title'>Medium</span>
                                            <span className='detail'>{record.inMedium}</span>
                                        </div>
                                        <div className='d-flex mb-1'>
                                            <span className='title'>Currency</span>
                                            <span className='detail'>{record.transferIn && record.transferIn.currency}</span>
                                        </div>
                                        <div className='d-flex mb-1'>
                                            <span className='title'>Receive Amount</span>
                                            <span className='detail'>{record.transferIn && record.transferIn.receiveAmount}</span>
                                        </div>
                                        <div className='d-flex mb-4'>
                                            <span className='title'>Send Amount</span>
                                            <span className='detail'>{record.transferIn && record.transferIn.sendAmount}</span>
                                        </div>
                                        <p className='text-secondary fw-bold mb-1'>Blockchain Details</p>
                                        <div className='d-flex mb-1'>
                                            <span className='title'>Address</span>
                                            <span className='detail'>{record?.transferIn?.details?.address}</span>
                                        </div>
                                    </div>
                                </div>
                                :
                                <div class="card-body">
                                    <h5 class="card-title">Transfer In</h5>
                                    <div className='trade-details'>
                                        <div className='d-flex mb-1'>
                                            <span className='title'>Medium</span>
                                            <span className='detail'>{record.inMedium}</span>
                                        </div>
                                        <div className='d-flex mb-1'>
                                            <span className='title'>Currency</span>
                                            <span className='detail'>{record.transferIn.currency}</span>
                                        </div>
                                        <div className='d-flex mb-1'>
                                            <span className='title'>Receive Amount</span>
                                            <span className='detail'>{record?.transferIn?.receiveAmount}</span>
                                        </div>
                                        <div className='d-flex mb-4'>
                                            <span className='title'>Send Amount</span>
                                            <span className='detail'>{record?.transferIn?.sendAmount}</span>
                                        </div>
                                        <p className='text-secondary fw-bold mb-1'>Account Details</p>
                                        <div className='d-flex mb-1'>
                                            <span className='title'>Name</span>
                                            <span className='detail'>{record?.transferIn?.details?.account?.name}</span>
                                        </div>
                                        <div className='d-flex mb-1'>
                                            <span className='title'>Number</span>
                                            <span className='detail'>{record?.transferIn?.details?.account?.number}</span>
                                        </div>
                                        <div className='d-flex mb-1'>
                                            <span className='title'>Bic</span>
                                            <span className='detail'>{record?.transferIn?.details?.account?.bic}</span>
                                        </div>
                                        <div className='d-flex mb-4'>
                                            <span className='title'>Currency</span>
                                            <span className='detail'>{record?.transferIn?.details?.account?.currency}</span>
                                        </div>
                                        <p className='text-secondary fw-bold mb-1'>Bank Details</p>
                                        <div className='d-flex mb-4'>
                                            <span className='title'>Address</span>
                                            <span className='detail'>{record?.transferIn?.details?.bank?.address}</span>
                                        </div>
                                        <p className='text-secondary fw-bold mb-1'>Holder Details</p>
                                        <div className='d-flex mb-1'>
                                            <span className='title'>City</span>
                                            <span className='detail'>{record?.transferIn?.details?.holder?.city}</span>
                                        </div>
                                        <div className='d-flex mb-1'>
                                            <span className='title'>Country</span>
                                            <span className='detail'>{record?.transferIn?.details?.holder?.country}</span>
                                        </div>
                                        <div className='d-flex mb-1'>
                                            <span className='title'>Name</span>
                                            <span className='detail'>{record?.transferIn?.details?.holder?.name}</span>
                                        </div>
                                        <div className='d-flex mb-1'>
                                            <span className='title'>State</span>
                                            <span className='detail'>{record?.transferIn?.details?.holder?.state}</span>
                                        </div>
                                        <div className='d-flex mb-1'>
                                            <span className='title'>Country</span>
                                            <span className='detail'>{record?.transferIn?.details?.holder?.zipcode}</span>
                                        </div>
                                    </div>
                                </div>
                        }
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="card">
                        {
                            record.inMedium != 'bank' ?
                                <div class="card-body">
                                    <h5 class="card-title">Transfer Out</h5>
                                    <div className='trade-details'>
                                        <div className='d-flex mb-1'>
                                            <span className='title'>Medium</span>
                                            <span className='detail'>{record.outMedium}</span>
                                        </div>
                                        <div className='d-flex mb-1'>
                                            <span className='title'>Currency</span>
                                            <span className='detail'>{record?.transferOut?.currency}</span>
                                        </div>
                                        <div className='d-flex mb-1'>
                                            <span className='title'>Receive Amount</span>
                                            <span className='detail'>{record?.transferOut?.receiveAmount}</span>
                                        </div>
                                        <div className='d-flex mb-4'>
                                            <span className='title'>Send Amount</span>
                                            <span className='detail'>{record?.transferOut?.sendAmount}</span>
                                        </div>
                                        <p className='text-secondary fw-bold mb-1'>Account Details</p>
                                        <div className='d-flex mb-1'>
                                            <span className='title'>Name</span>
                                            <span className='detail'>{record?.transferOut?.details?.account?.name}</span>
                                        </div>
                                        <div className='d-flex mb-1'>
                                            <span className='title'>Number</span>
                                            <span className='detail'>{record?.transferOut?.details?.account?.number}</span>
                                        </div>
                                        <div className='d-flex mb-1'>
                                            <span className='title'>Bic</span>
                                            <span className='detail'>{record?.transferOut?.details?.account?.bic}</span>
                                        </div>
                                        <div className='d-flex mb-4'>
                                            <span className='title'>Currency</span>
                                            <span className='detail'>{record?.transferOut?.details?.account?.currency}</span>
                                        </div>
                                        <p className='text-secondary fw-bold mb-1'>Bank Details</p>
                                        <div className='d-flex mb-4'>
                                            <span className='title'>Address</span>
                                            <span className='detail'>{record?.transferOut?.details?.bank?.address}</span>
                                        </div>
                                        <p className='text-secondary fw-bold mb-1'>Holder Details</p>
                                        <div className='d-flex mb-1'>
                                            <span className='title'>City</span>
                                            <span className='detail'>{record?.transferOut?.details?.holder?.city}</span>
                                        </div>
                                        <div className='d-flex mb-1'>
                                            <span className='title'>Country</span>
                                            <span className='detail'>{record?.transferOut?.details?.holder?.country}</span>
                                        </div>
                                        <div className='d-flex mb-1'>
                                            <span className='title'>Name</span>
                                            <span className='detail'>{record?.transferOut?.details?.holder?.name}</span>
                                        </div>
                                        <div className='d-flex mb-1'>
                                            <span className='title'>State</span>
                                            <span className='detail'>{record?.transferOut?.details?.holder?.state}</span>
                                        </div>
                                        <div className='d-flex mb-1'>
                                            <span className='title'>Country</span>
                                            <span className='detail'>{record?.transferOut?.details?.holder?.zipcode}</span>
                                        </div>
                                        {
                                            record?.state == 'completed' && <div className='d-flex mb-1'>
                                                <span className='title'>Trx ID</span>
                                                <span className='detail'>{record?.transferOut?.trxId}</span>
                                            </div>
                                        }
                                    </div>
                                </div>
                                :
                                <div class="card-body">
                                    <h5 class="card-title">Transfer Out</h5>
                                    <div className='trade-details'>
                                        <div className='d-flex mb-1'>
                                            <span className='title'>Medium</span>
                                            <span className='detail'>{record.outMedium}</span>
                                        </div>
                                        <div className='d-flex mb-1'>
                                            <span className='title'>Currency</span>
                                            <span className='detail'>{record?.transferOut?.currency}</span>
                                        </div>
                                        <div className='d-flex mb-1'>
                                            <span className='title'>Receive Amount</span>
                                            <span className='detail'>{record?.transferOut?.receiveAmount}</span>
                                        </div>
                                        <div className='d-flex mb-4'>
                                            <span className='title'>Send Amount</span>
                                            <span className='detail'>{record?.transferOut?.sendAmount}</span>
                                        </div>
                                        <p className='text-secondary fw-bold mb-1'>Blockchain Details</p>
                                        <div className='d-flex mb-1'>
                                            <span className='title'>Address</span>
                                            <span className='detail'>{record?.transferOut?.details?.account}</span>
                                        </div>
                                        {
                                            record?.state == 'completed' && <div className='d-flex mb-1'>
                                                <span className='title'>Trx ID</span>
                                                <span className='detail'>{record?.transferOut?.trxId}</span>
                                            </div>
                                        }
                                    </div>
                                </div>
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default AccountDetails;